import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
  forwardRef,
  shadow,
  Icon,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion, isValidMotionProp } from "framer-motion";

const MotionBox = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(([key]) => !isValidMotionProp(key))
    );
    return <Box ref={ref} {...chakraProps} />;
  })
);

const ServiceCard = ({ title, children, icon, ...props }) => {
  return (
    <MotionBox
      height="495px"
      width="322px"
      borderRadius="30px"
      boxShadow="0px 0px 26px #00000029"
      p="30px"
      pb="79px"
      {...props}
      initial={{
        scale: 1,
      }}
      whileHover={{
        scale: 1.05,
      }}
    >
      <Flex
        h="100%"
        justifyContent="flex-start"
        align="center"
        flexDir="column"
      >
        {icon}
        <Heading variant="service_label" mt="30px">
          {title}
        </Heading>
        <Text
          w="262px"
          mx="auto"
          lineHeight="28px"
          textAlign="center"
          fontSize="18px"
        >
          {children}
        </Text>
      </Flex>
    </MotionBox>
  );
};

const ServicesBenefits = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      bgColor="#FFFFFF"
      h={mobile ? "" : "1487px"}
      pt={mobile ? "30px" : "150px"}
    >
      <Box w={mobile ? "100%" : "1088px"} mx="auto">
        <Text
          textAlign="center"
          fontWeight="bold"
          fontSize="24px"
          px={mobile && 4}
        >
          You’ve never seen anything like this.
        </Text>
        <Stack
          pt={mobile ? "40px" : "90px"}
          spacing="61px"
          direction={mobile ? "column" : "row"}
          w={mobile && "100%"}
          alignItems={mobile && "center"}
        >
          <ServiceCard
            title="2-Day Shipping"
            icon={<Image src="/assets/clock.svg" h="170px" w="188px" />}
          >
            The shipping services of the largest companies is now accessible to
            even the smallest businesses. Ship to 90% of the US in 2 days.
          </ServiceCard>
          <ServiceCard
            title="Reduced Costs"
            icon={<Image src="/assets/save_money.svg" h="174px" w="187px" />}
            position={mobile ? "initial" : "relative"}
            top={mobile ? "initial" : "80px"}
            h={mobile ? "initial" : "448px"}
          >
            Entrustex helps each business maximize their savings by addressing
            their specific needs through personalized rates.
          </ServiceCard>
          <ServiceCard
            title="Cutting Edge Technology"
            icon={<Image src="/assets/computer.svg" h="169px" w="175px" />}
          >
            Gain access to exclusive web technology designed to make your
            shipping process streamlined and effective.
          </ServiceCard>
        </Stack>
        <Flex
          pt={mobile ? "80px" : "180px"}
          justifyContent="center"
          alignItems={mobile && "center"}
          flexDir={mobile ? "column" : "row"}
        >
          <Image
            src="/assets/connectivity_service.svg"
            h={mobile ? "" : "379px"}
            w={mobile ? "80%" : "536px"}
          />
          <Box
            pl={mobile ? "" : "108px"}
            pt={mobile ? "30px" : "75px"}
            px={mobile && 4}
            pb={mobile && "60px"}
          >
            <Heading
              fontSize="34px"
              fontWeight="medium"
              textColor="green_sheen"
            >
              Greater connectivity brings you more options.
            </Heading>
            <Text pt="30px" fontSize="18px" lineHeight="28px" textColor="jet">
              Utilizing our proprietary technology, customers can connect and
              ship easily via API or our multiple online shipping platforms.
              Your packages arrive faster and for less cost with detailed
              tracking all along the way.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default ServicesBenefits;
