import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Image,
  Flex,
  HStack,
  Stack,
  Input,
  Textarea,
  Text,
  VStack,
  Link,
  Center,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ButtonUnderlined from "./ButtonUnderlined";
import { NumPackage, WeighPackage, CurrentShip } from "./Icons";
import NextLink from "next/link";
import emailjs from "emailjs-com";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const SignupInput = ({ placeholder, type, state, setState, ...props }) => {
  let value = state;
  let setValue = setState;

  // Method to format phone number
  const parse = (phoneString) => {
    if (!phoneString) {
      return phoneString;
    } else {
      const phoneNum = phoneString.replace(/[^\d]/g, "");
      const numLength = phoneNum.length;

      if (numLength < 4) {
        return phoneNum;
      } else if (numLength < 7) {
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(3)}`;
      } else if (numLength >= 7) {
        return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(
          3,
          6
        )}-${phoneNum.slice(6, 10)}`;
      }
    }
  };

  return (
    <Input
      w="100%"
      variant="flushed"
      borderColor="jet"
      borderBottomWidth="2px"
      textColor="jet"
      fontSize="18px"
      lineHeight="28px"
      pb="13.5px"
      onChange={(e) => {
        if (type === "number" && Number.isInteger(Number(e.target.value))) {
          setValue(e.target.value);
        } else if (type === "tel") {
          setValue(parse(e.target.value));
        } else if (type !== "number") {
          setValue(e.target.value);
        }
      }}
      value={value}
      _focus={{ borderColor: "jet" }}
      _placeholder={{
        textColor: "jet",
        opacity: "62%",
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};

const SignupTextarea = ({
  placeholder,
  height = "100%",
  width = "100%",
  w = "100%",
  h = "100%",
  color = "jet",
  ...props
}) => {
  return (
    <Textarea
      w={w ? w : width}
      h={h ? h : height}
      variant="flushed"
      borderColor={color}
      borderWidth="2px"
      textColor={color}
      fontSize="18px"
      _focus={{ borderColor: color }}
      pl="18.5px"
      pr="18.5px"
      pt="15.38px"
      pb="15.38px"
      _placeholder={{
        textColor: color,
        opacity: "62%",
      }}
      resize="none"
      placeholder={placeholder}
      {...props}
    />
  );
};

const SignUpBlade = () => {
  const [showForm, setShowForm] = useState(false);
  const mobile = useBreakpointValue({ base: true, md: false });

  const ButtonBlade = () => {
    return (
      <Box w="100vw" overflow={mobile && "hidden"}>
        <Box w={mobile ? "100%" : "1088px"} mx="auto">
          <Flex h="100vh" align="center" flexDir={mobile ? "column" : "row"}>
            <Box my={mobile && "40px"} px={mobile && 4}>
              <Heading
                w={mobile ? "100%" : "388px"}
                fontSize="43px"
                textColor="jet"
                lineHeight="54px"
                fontWeight="regular"
              >
                Ready to change the way you deliver?
              </Heading>
              <Text
                w={mobile ? "100%" : "388px"}
                fontSize="18px"
                textColor="jet"
                lineHeight="28px"
                mt="25px"
              >
                Sign up to have a sales representative contact you for a FREE
                consultation.
              </Text>
              <ButtonUnderlined
                id="sign-up-btn"
                fontSize="18px"
                fontWeight="bold"
                textColor="jet"
                mt={mobile ? "25px" : "113px"}
                onClick={() => setShowForm(true)}
              >
                SIGN UP
              </ButtonUnderlined>
            </Box>
            <Image
              src="/assets/package_signup@2x.png"
              h={mobile ? "100%" : "491px"}
              w={mobile ? "" : "810px"}
              pos="relative"
              left="20px"
              alignSelf="flex-end"
            />
          </Flex>
        </Box>
      </Box>
    );
  };

  const FormBlade = () => {
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs
        .sendForm(
          "default_service",
          "template_zfj2y5k",
          e.target,
          "user_gFNNnK551Q8Q8X337mySS"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSubmitted(true);
            setLoading(false);
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

    const Form = () => {
      const [shipmentsPerDay, setShipmentsPerDay] = useState("");
      const [avgPackageWeight, setAvgPackageWeight] = useState("");
      const [currentProvider, setCurrentProvider] = useState("");
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [phone, setPhone] = useState("");
      const [message, setMessage] = useState("");

      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      //Get Google Recaptcha Token
      const { executeRecaptcha } = useGoogleReCaptcha();
      const recaptchaToken = executeRecaptcha("signup_form");

      return (
        <Stack
          as="form"
          spacing={mobile ? "" : "108.5px"}
          align="start"
          id="signup_form"
          onSubmit={(e) => handleSubmit(e)}
          direction={mobile ? "column" : "row"}
          overflowY={mobile && "scroll"}
          overflowX={mobile && "hidden"}
          h={mobile && "100vh"}
          w={mobile && "100vw"}
          py={mobile && "30px"}
          px={mobile && 4}
        >
          <VStack>
            <Flex>
              {!mobile && <Icon as={NumPackage} pos="relative" top="4.5px" />}
              <Box pl={mobile ? "" : "16px"} pb={mobile ? "30px" : "60px"}>
                <Heading
                  fontSize="24px"
                  fontWeight="bold"
                  textColor="jet"
                  w={mobile ? "100%" : "352px"}
                  pb="30px"
                  lineHeight="37px"
                >
                  How many 1-10 lb shipments do you send per day?*
                </Heading>
                <SignupInput
                  name="shipmentsPerDay"
                  type="number"
                  placeholder="# per day"
                  state={shipmentsPerDay}
                  setState={setShipmentsPerDay}
                  label="number of shipments per day"
                />
              </Box>
            </Flex>
            <Flex>
              {!mobile && <Icon as={WeighPackage} pos="relative" top="4.5px" />}
              <Box pl={mobile ? "" : "16px"} pb={mobile ? "30px" : "60px"}>
                <Heading
                  fontSize="24px"
                  fontWeight="bold"
                  textColor="jet"
                  w={mobile ? "100%" : "352px"}
                  pb="30px"
                  lineHeight="37px"
                >
                  What’s your average shipping weight?*
                </Heading>
                <SignupInput
                  name="avgPackageWeight"
                  type="number"
                  placeholder="# lbs"
                  state={avgPackageWeight}
                  setState={setAvgPackageWeight}
                  label="average shipment weight"
                />
              </Box>
            </Flex>
            <Flex>
              {!mobile && <Icon as={CurrentShip} pos="relative" top="4.5px" />}
              <Box pl={mobile ? "" : "16px"} pb={mobile ? "30px" : "60px"}>
                <Heading
                  fontSize="24px"
                  fontWeight="bold"
                  textColor="jet"
                  w={mobile ? "100%" : "352px"}
                  pb="30px"
                  lineHeight="37px"
                >
                  Who are your current shipping providers?*
                </Heading>
                <SignupInput
                  name="currentProvider"
                  type="text"
                  placeholder="ex. USPS"
                  state={currentProvider}
                  setState={setCurrentProvider}
                  label="current shipping providers"
                />
              </Box>
            </Flex>
          </VStack>

          <Box w="100%">
            <Heading
              fontSize="24px"
              fontWeight="bold"
              textColor="jet"
              w="444px"
              pb="30px"
              lineHeight="37px"
            >
              How can we contact you?*
            </Heading>
            <SignupInput
              name="name"
              w={mobile ? "100%" : "352px"}
              mb="30px"
              placeholder="Your Name"
              state={name}
              setState={setName}
              label="name"
            />
            <SignupInput
              name="email"
              w={mobile ? "100%" : "352px"}
              mb="30px"
              type="email"
              placeholder="Your Email"
              state={email}
              setState={setEmail}
              label="email"
            />
            <SignupInput
              name="phone"
              w={mobile ? "100%" : "352px"}
              mb="60px"
              type="tel"
              placeholder="Your phone #"
              state={phone}
              setState={setPhone}
              label="phone"
            />
            <SignupTextarea
              name="message"
              h="144px"
              color="jet"
              placeholder="Anything else you want us to know about you?"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
              label="message to send"
            />
            <Flex mt="69px" justifyContent="space-between">
              <Button
                variant="unstyled"
                type="submit"
                h="47px"
                w="113px"
                borderRadius="24px"
                bgColor="jet"
                textColor="bitter_lemon"
                fontSize="18px"
                letterSpacing="1.08px"
                lineHeight="28px"
                _disabled={{
                  opacity: "60%",
                  cursor: "not-allowed",
                  _hover: { opacity: "60%" },
                }}
                _hover={{ opacity: "90%" }}
                isDisabled={
                  !(
                    name &&
                    phone.length === 14 &&
                    emailRegex.test(email.toLowerCase()) &&
                    shipmentsPerDay &&
                    avgPackageWeight &&
                    currentProvider &&
                    recaptchaToken
                  )
                }
                isLoading={loading}
                spinner={<Spinner size="md" pos="relative" left="44px" />}
                label="submit"
              >
                SUBMIT
              </Button>
              <Box>
                <Text
                  textColor="jet"
                  fontSize="16px"
                  lineHeight="24px"
                  textAlign="right"
                >
                  *Required <br />
                  Your{" "}
                  <NextLink href="/docs/privacy" passHref>
                    <Link target="_blank" textDecor="underline">
                      privacy
                    </Link>
                  </NextLink>{" "}
                  is important to us.
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
      );
    };

    return (
      <Flex h="100vh" w="100vw" align="center">
        <Box w={mobile ? "100%" : "1088px"} mx="auto">
          <AnimatePresence exitBeforeEnter>
            {submitted ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key="success"
              >
                <Center flexDir="column">
                  <Image src="/assets/success.svg" boxSize="46px" mb="30px" />
                  <Heading
                    fontSize="43px"
                    lineHeight="54px"
                    fontWeight="regular"
                    textAlign="center"
                  >
                    Thanks! We’ll be in touch soon.
                  </Heading>
                </Center>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="form"
              >
                <Form />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Flex>
    );
  };

  return (
    <Flex
      h="100vh"
      align="center"
      bgColor="bitter_lemon"
      maxW="100vw"
      overflow="hidden"
      id="sign-up"
    >
      <motion.div
        initial={{ x: 0 }}
        animate={showForm ? { x: "-100vw" } : { x: 0 }}
        transition={{ type: "spring", duration: 1 }}
      >
        <HStack w="200vw">
          <ButtonBlade />
          <FormBlade />
        </HStack>
      </motion.div>
    </Flex>
  );
};

export default SignUpBlade;
