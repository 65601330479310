import { createIcon } from "@chakra-ui/icons";

export const EntrustexLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149.433"
      height="17.855"
      viewBox="0 0 149.433 17.855"
      style={{ fill: "#7bf9bd" }}
    >
      <g transform="translate(491.621 149)">
        <rect
          className="a"
          width="12.339"
          height="2.874"
          transform="translate(-489.441 -134.326)"
        />
        <g transform="translate(-491.621 -149)">
          <rect
            className="a"
            width="12.16"
            height="2.874"
            transform="translate(2.181 0.334)"
          />
          <rect
            className="a"
            width="11.621"
            height="2.874"
            transform="translate(0 7.478)"
          />
          <g transform="translate(18.164 0)">
            <path
              className="a"
              d="M644.845,89.681h-3.027V74.7a2.669,2.669,0,0,1,.346-1.4,2.288,2.288,0,0,1,.911-.872,2.618,2.618,0,0,1,1.231-.3,3.17,3.17,0,0,1,1.437.308,2.324,2.324,0,0,1,1,1.026l7.286,13.058a.28.28,0,0,1-.013.09c-.009.026,0,.038.013.038l-.051.026V72.467h3.027V87.449a2.53,2.53,0,0,1-.359,1.385,2.434,2.434,0,0,1-.911.86,2.494,2.494,0,0,1-1.193.295,3.189,3.189,0,0,1-1.424-.321,2.371,2.371,0,0,1-1.039-1.013l-7.234-13.032a.256.256,0,0,1,.013-.1c.008-.017,0-.026-.013-.026Z"
              transform="translate(-641.818 -72.134)"
            />
            <path
              className="a"
              d="M706.571,73.093v2.925H693.026V73.093Zm-8.363,0h3.181V90.307h-3.181Z"
              transform="translate(-675.222 -72.76)"
            />
            <path
              className="a"
              d="M743.183,90.307h-3.156V73.093h6.337a9.45,9.45,0,0,1,3.386.577,5.261,5.261,0,0,1,2.4,1.757,4.866,4.866,0,0,1,.885,3,4.949,4.949,0,0,1-.654,2.553,4.564,4.564,0,0,1-1.834,1.744,5.865,5.865,0,0,1-2.8.629l-.872-.667a5.151,5.151,0,0,1,2.424.487,4.313,4.313,0,0,1,1.552,1.449,20.165,20.165,0,0,1,1.334,2.4l1.616,3.283h-3.437l-1.488-2.976a8.82,8.82,0,0,0-.923-1.565,3.355,3.355,0,0,0-1.18-.987,4.024,4.024,0,0,0-1.8-.347H742.85V81.585h3.489a4.508,4.508,0,0,0,1.783-.333,2.751,2.751,0,0,0,1.218-.962,2.657,2.657,0,0,0,.436-1.552,2.519,2.519,0,0,0-.449-1.565,2.623,2.623,0,0,0-1.219-.872,4.964,4.964,0,0,0-1.719-.282h-3.181Z"
              transform="translate(-705.883 -72.76)"
            />
            <path
              className="a"
              d="M796.234,90.563q-3.72,0-5.5-2.078a8.542,8.542,0,0,1-1.783-5.747V73.093h3.181v9.569a5.572,5.572,0,0,0,1.026,3.643,4.512,4.512,0,0,0,6.2,0,5.625,5.625,0,0,0,1.013-3.643V73.093h3.181v9.646a8.544,8.544,0,0,1-1.783,5.747Q799.979,90.563,796.234,90.563Z"
              transform="translate(-737.795 -72.76)"
            />
            <path
              className="a"
              d="M846.82,90.086a8.025,8.025,0,0,1-3.964-.962,5.872,5.872,0,0,1-2.553-2.758l2.668-1.334a3.26,3.26,0,0,0,1.449,1.565,4.948,4.948,0,0,0,2.4.539,4.865,4.865,0,0,0,1.642-.256,2.553,2.553,0,0,0,1.142-.77,1.874,1.874,0,0,0,.423-1.231,1.681,1.681,0,0,0-.462-1.283,2.919,2.919,0,0,0-1.244-.654,15.865,15.865,0,0,0-1.757-.372q-.975-.179-2.014-.475a7.421,7.421,0,0,1-1.937-.859,4.149,4.149,0,0,1-2-3.8,4.494,4.494,0,0,1,.808-2.642,5.674,5.674,0,0,1,2.18-1.834,6.814,6.814,0,0,1,3.066-.68,7.518,7.518,0,0,1,3.745.936,5.684,5.684,0,0,1,2.463,2.732l-2.642,1.334a3.481,3.481,0,0,0-1.4-1.5,4.453,4.453,0,0,0-2.3-.552,3.672,3.672,0,0,0-1.4.257,2.292,2.292,0,0,0-.988.731,1.758,1.758,0,0,0-.359,1.09,1.638,1.638,0,0,0,.487,1.27,3.27,3.27,0,0,0,1.3.693,16.076,16.076,0,0,0,1.809.423,20.413,20.413,0,0,1,1.988.475,7.132,7.132,0,0,1,1.885.859,4.4,4.4,0,0,1,1.411,1.475,4.565,4.565,0,0,1,.539,2.348,4.457,4.457,0,0,1-.859,2.693,5.9,5.9,0,0,1-2.3,1.86A7.476,7.476,0,0,1,846.82,90.086Z"
              transform="translate(-771.295 -72.231)"
            />
            <path
              className="a"
              d="M895.686,73.093v2.925H882.141V73.093Zm-8.363,0H890.5V90.307h-3.181Z"
              transform="translate(-798.587 -72.76)"
            />
            <path
              className="a"
              d="M940.744,90.307h-9.466a2.8,2.8,0,0,1-2.219-.757,3.01,3.01,0,0,1-.654-2.039V75.864a2.962,2.962,0,0,1,.654-2.027,2.833,2.833,0,0,1,2.219-.744h9.287v2.873h-8.979V87.434h9.158Zm-.718-7.183h-9.21l-.411-2.873h9.62Z"
              transform="translate(-828.765 -72.76)"
            />
            <path
              className="a"
              d="M972.956,90.307h-3.822l4.233-6.516a7.646,7.646,0,0,0,.667-1.231,2.637,2.637,0,0,0,.2-.975,2.557,2.557,0,0,0-.218-1,8.834,8.834,0,0,0-.654-1.206l-4.054-6.285h3.72l3.309,5.208a6.619,6.619,0,0,1,1.026,3.412,5.658,5.658,0,0,1-.256,1.642,6.884,6.884,0,0,1-.8,1.693Zm6.953-10.082v2.9h-3.8v-2.9Zm6.7,10.082h-3.822l-3.361-5.259a6.851,6.851,0,0,1-.8-1.693,5.637,5.637,0,0,1-.257-1.642A6.529,6.529,0,0,1,979.4,78.3l3.31-5.208h3.72l-4.079,6.285a7.707,7.707,0,0,0-.641,1.206,2.685,2.685,0,0,0-.206,1,2.625,2.625,0,0,0,.206.975,8.6,8.6,0,0,0,.641,1.231Z"
              transform="translate(-855.334 -72.76)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
