import {
  Box,
  Heading,
  Icon,
  Text,
  Button,
  Flex,
  HStack,
  Link,
  Image,
  Divider,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { EntrustexLogo } from "./EntrustexLogo";
import ButtonUnderlined from "./ButtonUnderlined";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useRef } from "react";

const NavLink = ({ href = "/", children }) => {
  return (
    <NextLink href={href} passHref>
      <a>
        <Heading variant="h3" fontSize="18px" textTransform="uppercase">
          {children}
        </Heading>
      </a>
    </NextLink>
  );
};

const MobileDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <IconButton
        icon={<HamburgerIcon />}
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        fontSize="1.5em"
      />
      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bgColor="jet">
          <DrawerCloseButton color="antique_white" mt="1.2em" mr="10px" />
          <DrawerBody>
            <NavLink href="/tracking">Tracking</NavLink>
            <NavLink href="/docs/api">API Documentation</NavLink>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const NavigationBar = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      pt={mobile ? 4 : "73px"}
      mx={mobile && "20px"}
      justifyContent="space-between"
    >
      <HStack spacing="50px">
        <Icon as={EntrustexLogo} />
        {!mobile && (
          <>
            <NavLink href="/tracking">Tracking</NavLink>
            <NavLink href="/docs/api">API Documentation</NavLink>
          </>
        )}
      </HStack>
      {!mobile && (
        <ButtonUnderlined
          as={Link}
          textColor="aquamarine"
          _hover={{ textDecor: "none" }}
          href="#sign-up"
          onClick={() => {
            document.getElementById("sign-up-btn").click();
          }}
          zIndex="1"
        >
          GET STARTED
        </ButtonUnderlined>
      )}
      {mobile && <MobileDrawer />}
    </Flex>
  );
};

const LandingBox = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      bgGradient="linear(to-b, jet, #101010)"
      h={mobile ? "min-content" : "891px"}
    >
      <Box w={mobile ? "100%" : "1088px"} mx="auto">
        <NavigationBar />
        <Flex
          pt={mobile ? "" : "116px"}
          flexDir={mobile && "column"}
          pb={mobile && 8}
        >
          <Box
            minW={mobile ? "100%" : "430px"}
            w={mobile ? "100%" : "430px"}
            pt={mobile ? "20px" : "49px"}
            px={mobile ? 4 : "unset"}
            mx={mobile && "auto"}
            id="content-box"
          >
            <Heading variant="h1">
              We created a better way to put your packages on the map.
            </Heading>
            <Text variant="body" w="98%" pt={mobile ? "26px" : "26px"}>
              Entrustex helps D2C brands reach their customers at unprecedented
              speeds which gives your business greater freedom.
            </Text>
            <HStack
              spacing="24.5px"
              w="100%"
              justify="center"
              pt={mobile ? "30px" : "62.5px"}
            >
              <Box mr="-15px">
                <Image src="/assets/create.svg" mx="auto" size="40px" />
                <Heading variant="label">Create</Heading>
              </Box>
              <Box w="92px" alignSelf="start">
                <Divider
                  borderColor="antique_white"
                  borderWidth="1px"
                  mt="20px"
                  opacity="100%"
                />
              </Box>
              <Box>
                <Image src="/assets/ship_package.svg" mx="auto" size="40px" />
                <Heading variant="label">Ship</Heading>
              </Box>
              <Box w="92px" alignSelf="start">
                <Divider
                  borderColor="antique_white"
                  borderWidth="1px"
                  mt="20px"
                  opacity="100%"
                />
              </Box>
              <Box>
                <Image src="/assets/save_piggy.svg" mx="auto" size="40px" />
                <Heading variant="label">Save</Heading>
              </Box>
            </HStack>
          </Box>
          {!mobile ? (
            <Image
              pos={mobile ? "relative" : "relative"}
              top={mobile ? "-395px" : "-264px"}
              left={mobile ? "unset" : "159px"}
              right={mobile && "-180px"}
              zIndex={mobile ? "0" : "0"}
              src="/assets/landing_graphic.svg"
              h={mobile ? "200px" : "802px"}
              w={mobile ? "300px" : "706px"}
            />
          ) : (
            <ButtonUnderlined
              as={Link}
              textColor="aquamarine"
              _hover={{ textDecor: "none" }}
              href="#sign-up"
              onClick={() => {
                document.getElementById("sign-up-btn").click();
              }}
              fontSize="1.5em"
              mt={6}
              zIndex="1"
            >
              GET STARTED
            </ButtonUnderlined>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default LandingBox;
