import Icon from "@chakra-ui/icon";
import { Box, Flex, Text, Link } from "@chakra-ui/layout";
import { EXLogo } from "./Icons";
import NextLink from "next/link";
import { useBreakpointValue } from "@chakra-ui/media-query";

const Footer = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box h={mobile ? "100%" : "402px"} bgColor="jet">
      <Flex
        w={mobile ? "100%" : "1088px"}
        mx="auto"
        pt={mobile ? "" : "104px"}
        py={"40px"}
        px={mobile && 4}
        textColor="antique_white"
        fontSize="18px"
        lineHeight="28px"
        letterSpacing="1.08px"
        direction={mobile ? "column" : "row"}
      >
        <Box h={mobile ? "100%" : "223px"}>
          <Icon as={EXLogo} />
          <Text fontWeight="regular" mt="30.02px" w={mobile ? "100%" : "628px"}>
            Entrustex is dedicated to providing cutting edge shipping solutions
            to forward focused brands and companies. We have decades of
            experience in the small package industry and are focused on helping
            you be profitable and successful through the superb use of
            technology and ingenuity.
          </Text>
        </Box>
        <Box
          borderLeft={mobile ? "" : "2px solid"}
          borderTop={mobile && "2px solid"}
          borderColor="antique_white"
          ml={mobile ? "" : "108px"}
          pl={mobile ? "" : "76px"}
          mt={mobile && "20px"}
          pt={mobile && "20px"}
        >
          <Text w={mobile ? "" : "628px"} fontWeight="medium">
            Entrustex Inc.
          </Text>
          <Text fontWeight="regular" w="628px">
            16165 North 83rd Avenue, <br />
            Suite 200,
            <br />
            Peoria, AZ 85382
            <br />
            (888) 425-6160
          </Text>
          <Box mt={mobile && "20px"}>
            <NextLink href="/docs/privacy" passHref>
              <Link
                textDecor="underline"
                fontWeight="bold"
                pos={mobile ? "" : "relative"}
                top={mobile ? "" : "53.5px"}
                target="_blank"
              >
                Privacy Policy
              </Link>
            </NextLink>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
