import Head from "next/head";
import LandingBox from "../components/LandingBox";
import ServicesBenefits from "../components/ServicesBenefits";
import SignUpBlade from "../components/SignUpBlade";
import ContactBlade from "../components/ContactBlade";
import Footer from "../components/Footer";
import { Box } from "@chakra-ui/layout";

const Home = () => {
  return (
    <Box maxW="100vw" overflow="hidden">
      <Head>
        <title>Entrustex</title>
      </Head>
      <LandingBox />
      <ServicesBenefits />
      <SignUpBlade />
      <ContactBlade />
      <Footer />
    </Box>
  );
};

export default Home;
