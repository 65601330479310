import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Input } from "@chakra-ui/input";
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import { Headphones, Mail } from "./Icons";
import ButtonUnderlined from "./ButtonUnderlined";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import emailjs from "emailjs-com";
import { Image } from "@chakra-ui/image";
import { Spinner } from "@chakra-ui/spinner";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactButton = ({ href = "/", icon, children, ...props }) => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Button
      as={Link}
      _hover={{ textDecor: "none", opacity: "90%" }}
      _focus={{ opacity: "90%", shadow: "none" }}
      href={href}
      w={mobile ? "100%" : "352px"}
      bgColor="jet"
      borderRadius="30px"
      h="60px"
      justifyContent="left"
      textColor="aquamarine"
      fontWeight="bold"
      textDecor="none"
      fontSize="18px"
      lineHeight="28px"
      letterSpacing="1.08px"
      {...props}
    >
      <HStack pl="24.86px" spacing="18.39px">
        <Icon as={icon} />
        <Text>{children}</Text>
      </HStack>
    </Button>
  );
};

const ContactInput = ({ placeholder, ...props }) => {
  return (
    <Input
      w="100%"
      variant="flushed"
      borderColor="jet"
      borderBottomWidth="2px"
      textColor="jet"
      fontSize="18px"
      lineHeight="28px"
      pb="13.5px"
      _focus={{ borderColor: "jet" }}
      _placeholder={{
        textColor: "jet",
        opacity: "62%",
      }}
      placeholder={placeholder}
      {...props}
    />
  );
};

const Form = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //Get Google Recaptcha Token
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaToken = executeRecaptcha("contact_form");

  return (
    <Box
      as="form"
      id="contact_form"
      ml={mobile ? "" : "200px"}
      onSubmit={(e) => {
        e.preventDefault();

        if (!emailRegex.test(email.toLowerCase())) {
          setFormInvalid(true);
        } else {
          setLoading(true);
          emailjs
            .sendForm(
              "default_service",
              "template_zgsz1ax",
              e.target,
              "user_gFNNnK551Q8Q8X337mySS"
            )
            .then(
              (result) => {
                console.log(result.text);
                setSubmitted(true);
                setLoading(false);
              },
              (error) => {
                console.log(error.text);
              }
            );
        }
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {submitted ? (
          <motion.div
            key="success"
            style={{ height: "100%" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Center
              flexDir="column"
              h="100%"
              w="100%"
              ml={mobile ? "" : "60px"}
            >
              <Image src="/assets/success.svg" boxSize="46px" />
              <Heading
                mt="30px"
                w={mobile ? "100%" : "352px"}
                fontSize="24px"
                fontWeight="bold"
                lineHeight="37px"
                textColor="jet"
                textAlign="center"
              >
                Request received. Watch your email, be in touch soon.
              </Heading>
            </Center>
          </motion.div>
        ) : (
          <motion.div key="form" exit={{ opacity: 0 }}>
            <ContactInput
              w={mobile ? "100%" : "352px"}
              placeholder="Your Name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <ContactInput
              name="email"
              w={mobile ? "100%" : "352px"}
              mt="24.25px"
              placeholder="Your email"
              type="text"
              value={email}
              onChange={(e) => {
                setFormInvalid(false);
                setEmail(e.target.value);
              }}
            />
            <Textarea
              name="message"
              mt="24.25px"
              w="100%"
              h="192px"
              variant="flushed"
              borderColor="jet"
              borderWidth="2px"
              textColor="jet"
              fontSize="18px"
              _focus={{ borderColor: "jet" }}
              pl="18.5px"
              pr="18.5px"
              pt="15.38px"
              pb="15.38px"
              _placeholder={{
                textColor: "jet",
                opacity: "62%",
              }}
              resize="none"
              placeholder="Tell us a bit about what you're looking for."
            />
            <Flex mt={mobile ? "20px" : "60px"}>
              <ButtonUnderlined
                fontSize="18px"
                fontWeight="bold"
                textColor="jet"
                type="submit"
                onClick={() => "none"}
                isDisabled={!recaptchaToken}
              >
                SUBMIT
              </ButtonUnderlined>
              {formInvalid && (
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: 6 }}
                  transition={{ type: "spring", bounce: 0.85 }}
                >
                  <Button
                    as={Text}
                    textDecor="none"
                    fontSize="18px"
                    fontWeight="bold"
                    textColor="red"
                    pb="2px"
                  >
                    &mdash; INVALID EMAIL
                  </Button>
                </motion.div>
              )}
              {loading && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Spinner
                    pos="relative"
                    top="11px"
                    left="6px"
                    thickness="3px"
                    color="jet"
                  />
                </motion.div>
              )}
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

const ContactBlade = () => {
  const mobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box h={mobile ? "" : "610px"} bgColor="aquamarine">
      <Box
        w={mobile ? "100%" : "1088px"}
        mx="auto"
        px={mobile && 4}
        py={mobile && "30px"}
      >
        <Flex pt={mobile ? "" : "90px"} flexDir={mobile && "column"}>
          <Box w={mobile ? "100%" : "352px"}>
            <Heading
              w="298px"
              textColor="jet"
              fontSize="36px"
              fontWeight="medium"
              lineHeight="46px"
            >
              Have questions?
              <br />
              Need support?
              <br />
              Let’s get in touch.
            </Heading>
            <Text
              pt="20px"
              fontSize="18px"
              textColor="jet"
              lineHeight="28px"
              fontWeight="regular"
            >
              Reach out to learn more about our services and pricing and we’ll
              get right back to you.
            </Text>
            <VStack
              mt={mobile ? "40px" : "81px"}
              mb={mobile && "40px"}
              spacing="15px"
            >
              <ContactButton icon={Headphones} href="tel:+18884256160">
                (888) 425-6160
              </ContactButton>
              <ContactButton icon={Mail} href="mailto:support@entrustex.com">
                support@entrustex.com
              </ContactButton>
            </VStack>
          </Box>
          <Form />
        </Flex>
      </Box>
    </Box>
  );
};

export default ContactBlade;
