import { Button } from "@chakra-ui/button";
import { Box, VStack } from "@chakra-ui/layout";
import { useToken } from "@chakra-ui/system";
import { motion } from "framer-motion";
import { useState } from "react";

const ButtonUnderlined = ({
  onClick = () => {
    window.alert("Button Clicked");
  },
  children,
  textColor,
  textLineSpace = "0px",
  ...props
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Button textDecor="none" textColor={textColor} onClick={onClick} {...props}>
      <motion.div
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        _hover={{ cursor: "pointer" }}
      >
        <Box w="100%" overflow="hidden">
          {children}
          <motion.div
            style={{
              height: "2px",
              width: "300%",
              display: "flex",
              marginTop: textLineSpace,
            }}
            initial={{ x: "-100%" }}
            animate={hover ? { x: 0 } : { x: "-66.666%" }}
            transition={{ duration: 0.6 }}
          >
            <Box bgColor={textColor} h="100%" w="100%" />
            <Box bgColor="transparent" h="100%" w="100%" />
            <Box bgColor={textColor} h="100%" w="100%" />
          </motion.div>
        </Box>
      </motion.div>
    </Button>
  );
};

export default ButtonUnderlined;
